import { 
    PathCategories,
    PathCategoryResponse,
    PathGpsCoordinates,
    PathResponse,
    Paths,
    PoiCategories,
    PoiCategoryResponse,
    PoiResponse,
    Pois,
    SnowmobilePathResponse,
    SnowmobilePaths,
    SnowmobileSubPathResponse,
    SnowmobileSubPaths,
    AoiResponse,
    Aois,
    AoiCategoryResponse,
    AoiCategories,
    AoiGpsCoordinates
} from "../interfaces";

/**
 * Converts the PathResponse array to a useable hashmap of paths
 */
export const toPaths = (paths: PathResponse[]): Paths => {
    const reduced = paths.reduce((acc, path) => ({
        ...acc,
        [`${path.id}`]: {
            ...path,
            id: `${path.id}`,
            pathCategoryId: `${path.pathCategoryId}`,
            tags: path.tags.map(({id, name, name_en}) => ({id, name, name_en})),
            areas: path.areas.map(({id, name, name_en}) => ({id, name, name_en})),
            pois: path.pois.map(({id, name, name_en}) => ({id, name, name_en})),
            ...(path.medias?.length ? {imageUrls: path.medias.map(({fullUrl}) => fullUrl)} : {}),
        },
    }), {});

    return reduced as Paths;
};

/**
 * Converts the PathResponse array to a useable hashmap of GPS coordinates
 */
export const toPathGpsCoordinates = (paths: PathResponse[]): PathGpsCoordinates => {
    const reduced = paths.reduce((acc, path) => ({
        ...acc,
        [`${path.id}`]: path.pathGpsCoordinates.map((coordinate: any) => ({
            ...coordinate,
        })),
    }), {});

    return reduced as PathGpsCoordinates;
};

/**
 * Converts both PathCategoryResponse array to a useable hashmap of path categories
 */
export const toPathCategories = (categories: PathCategoryResponse[]): PathCategories => {
    const reduced = categories.reduce((acc, category) => ({
        ...acc,
        [`${category.id}`]: {
            ...category,
            id: `${category.id}`,
            imageUrl: category.media?.fullUrl,
        },
    }), {});

    return reduced as PathCategories;
};

/**
 * Converts the PoiResponse array to a useable hashmap of pois formatted for the map
 */
 export const toPois = (pois: PoiResponse[]): Pois => {
    const reduced = pois.reduce((acc, poi) => ({
        ...acc,
        [`${poi.id}`]: {
            ...poi,
            id: `${poi.id}`,
            poiCategoryId: `${poi.pointOfInterestCategoryId}`,
            pointOfInterestCategoryId: undefined,
            areas: poi.areas.map(({id, name, name_en}) => ({id, name, name_en})),
            ...(poi.medias?.length ? {imageUrls: poi.medias.map(({fullUrl}) => fullUrl)} : {}),
        },
    }), {});

    return reduced as Pois;
};

/**
 * Converts both PathCategoryResponse array to a useable hashmap of path categories
 */
export const toPoiCategories = (categories: PoiCategoryResponse[]): PoiCategories => {
    const reduced = categories.reduce((acc, category) => ({
        ...acc,
        [`${category.id}`]: {
            ...category,
            id: `${category.id}`,
            imageUrl: category.media?.fullUrl,
        },
    }), {});

    return reduced as PoiCategories;
};

/**
 * Converts the SnowmobilePathResponse array to a useable hashmap of snowmobile paths
 */
export const toSnowmobilePaths = (paths: SnowmobilePathResponse[]): SnowmobilePaths => {
    const reduced = paths.reduce((acc, path) => ({
        ...acc,
        [`${path.id}`]: {
            id: `${path.id}`,
            subPaths: path.subPaths.map(subPath => `${subPath.id}`),
        },
    }), {});

    return reduced as SnowmobilePaths;
};

/**
 * Converts the SnowmobileSubPathResponse array to a useable hashmap of snowmobile subpaths
 */
export const toSnowmobileSubPaths = (paths: SnowmobileSubPathResponse[]): SnowmobileSubPaths => {
    const reduced = paths.reduce((acc, subPath) => ({
        ...acc,
        [`${subPath.id}`]: {
            ...subPath,
            id: `${subPath.id}`,
            status: subPath.status,
            subPathGPSCoordinates: subPath.subPathGPSCoordinates.map(gpsCoordinates => ({
                longitude: gpsCoordinates.longitude,
                latitude: gpsCoordinates.latitude,
            })),
            areas: subPath.areas.map(({id, name, name_en}) => ({id, name, name_en})),
        },
    }), {});

    return reduced as SnowmobileSubPaths;
};

/**
 * Converts the numeric length to a presentable kilometer string
 */
export const toKilometerString = (length: number): string => {
    return `${length.toFixed(1).replace('.', ',')} km`;
};

/**
 * Converts the AoiResponse array to a useable hashmap of aois formatted for the map
 */
export const toAois = (aois: AoiResponse[]): Aois => {
    const reduced = aois.reduce((acc, aoi) => ({
        ...acc,
        [`${aoi.id}`]: {
            ...aoi,
            id: `${aoi.id}`,
            aoiCategoryId: `${aoi.areaOfInterestCategoryId}`,
            areaOfInterestCategoryId: undefined,
            areas: aoi?.areas?.map(({id, name, name_en}) => ({id, name, name_en})),
            ...(aoi.medias?.length ? {imageUrls: aoi.medias.map(({fullUrl}) => fullUrl)} : {}),
        },
    }), {});

    return reduced as Aois;
};

/**
 * Converts both AoiCategoryResponse array to a useable hashmap of path categories
 */
export const toAoiCategories = (categories: AoiCategoryResponse[]): AoiCategories => {
    const reduced = categories.reduce((acc, category) => ({
        ...acc,
        [`${category.id}`]: {
            ...category,
            id: `${category.id}`,
            imageUrl: category.media?.fullUrl,
        },
    }), {});

    return reduced as AoiCategories;
};

/**
 * Converts the AoiResponse array to a useable hashmap of GPS coordinates
 */
export const toAoiGpsCoordinates = (aois: AoiResponse[]): AoiGpsCoordinates => {
    const reduced = aois.reduce((acc, aoi) => ({
        ...acc,
        [`${aoi.id}`]: aoi.aoiGpsCoordinates.map((coordinate: any) => ({
            ...coordinate,
        })),
    }), {});

    return reduced as AoiGpsCoordinates;
};
