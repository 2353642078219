import { ReactComponent as BurgerMenu } from './icons/menu-icon-open.svg';
import { ReactComponent as MenuIconLeft } from './icons/menu-icon-left.svg';
import { ReactComponent as MenuIconSearch } from './icons/menu-icon-search.svg';
import { ReactComponent as Logo } from './logo-miqmap.svg';
import { ReactComponent as MenuMap } from './icons/menu-map-icon.svg';


const splashBg = require('./splash-bg.jpg');

export {
    BurgerMenu,
    MenuMap,
    MenuIconLeft,
    MenuIconSearch,
    Logo,
    splashBg,
};