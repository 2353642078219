import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const navigationHeaderStyle = (theme: Theme) => StyleSheet.create({
    container: {
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 6 * theme.units.base,
        paddingHorizontal: theme.spacing.sides,
    },
    inverted: {
        color: theme.color.textInverted,
    },
    title: {
        fontSize: theme.fontSize.large,
    },
    iconContainer: {
        width: theme.iconSize.large,
        height: theme.iconSize.large,
    },
    icon: {
        flex: 1,
        color: theme.color.textPrimary
    },
    row: {
        flexDirection: 'row'
    }
});

export { navigationHeaderStyle };