import { TouchableOpacity, View, ViewProps, Platform } from "react-native";
import { withExternalStyle } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { ThemedText } from "../themed-text";
import { navigationHeaderStyle } from "./navigation-header.style";
import { IconClose, ArrowBack } from '../../assets';

type NavigationHeaderProps = ViewProps & {
    title: string;
    leftAlign?: boolean;
    inverted?: boolean;
    onBack?: () => void;
};

const NavigationHeader = (props: NavigationHeaderProps) => {
    const {style: styleProp, title, inverted, leftAlign, onBack} = props;

    const style = useThemedStyleFunction(navigationHeaderStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);
    const titleStyle = {...style.title, ...(inverted ? {color: style.inverted.color} : {})};
    const iconStyle = {...style.icon, ...(inverted ? {color: style.inverted.color} : {})};


    return (
        <View style={containerStyle}>
             {Platform.OS !== 'web' && (onBack || !leftAlign) &&
                <View style={style.iconContainer}>
                    {onBack && 
                        <TouchableOpacity onPress={onBack}>
                            <ArrowBack style={iconStyle}/>
                        </TouchableOpacity>
                    }
                </View>
            }
            <ThemedText style={titleStyle} type={'bold'}>
                {title}
            </ThemedText>
            <View style={style.row}>
                <View style={style.iconContainer}>
                    {onBack && Platform.OS === 'web' &&
                        <TouchableOpacity onPress={onBack}>
                            <IconClose style={style.icon}/>
                        </TouchableOpacity>
                    }
                </View>
            </View>
        </View>
    );
};

export { NavigationHeader };